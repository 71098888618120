import React from "react";
import MessageCard from "./MessageCard";
import { useSelector } from "react-redux";
import { selectKToken } from "../../general/keycloakTokenSlice";


const UserMessage = React.memo(({ messageBody,user,timeStamp }) => {
  
  return (
    <div style={{ maxWidth: '100%',textAlign: 'right' }}>
    <MessageCard
      messageBody={messageBody}
      timeStamp={timeStamp}
      sender={user}
      position={"end"}
    ></MessageCard></div>
  );
});

export default UserMessage;
