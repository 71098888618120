import "../../../App.css";
import React, { useState } from "react";
import { Button, Collapse } from "react-bootstrap";
import Thought from "./Thought";
import { useSelector } from "react-redux";
import { selectUser } from "../../general/usertTypeSlice";

const ChainOfThought = React.memo(({ chain }) => {
  const [open, setOpen] = useState(false);
    const user = useSelector(selectUser);
  return (

    
    <div  className={` ${user === "privato" ? "header-privato" : "header-impresa"}`} 
    >
      <Button  className={`mb-2 d-flex align-items-center ${user === "privato" ? "bg-privato" : "bg-impresa"}`} 
        onClick={() => setOpen(!open)}
        aria-controls="example-collapse-text"
        aria-expanded={open}
        
      >
        <i className="bi bi-chat me-2"></i>
        Dossier
        <i className={`bi bi-chevron-${open ? "up" : "down"} ms-2`}></i>
      </Button>
      <div style={{ width: '100%', maxWidth: '100%', margin: '0 auto' }}>
      <Collapse in={open}>
        <div id="example-collapse-text" >
          {chain.map((thought, key) => (
            <Thought key={key} thought={thought}></Thought>
          ))}
        </div>
      </Collapse>
      </div>
    </div>
  );
});

export default ChainOfThought;
