import { combineReducers } from '@reduxjs/toolkit';
import chatsReducer from '../features/chats/chatsSlice';
import messagesReducer from '../features/messages/messagesSlice';
import leftSidebarReducer from '../features/leftSidebar/leftSidebarSlice';
import rightSidebarReducer from '../features/rightSidebar/rightSidebarSlice';
import keycloakTokenReducer from '../features/general/keycloakTokenSlice';
import userTypeReducer from '../features/general/usertTypeSlice';
import licenseTypeReducer from '../features/general/licenseTypeSlice';
import cfTypeReducer from '../features/general/cfTypeSlice';

const rootReducer = combineReducers({
  chats: chatsReducer,
  messages: messagesReducer,
  leftSidebar: leftSidebarReducer,
  rightSidebar: rightSidebarReducer,
  keycloakToken: keycloakTokenReducer,
  userType : userTypeReducer,
  licenseType : licenseTypeReducer,
  cfType : cfTypeReducer,

});

export default rootReducer;
