import React, { useEffect, useState } from "react";
import { Col, Container, ListGroup, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { fetchChats } from "../features/chats/chatsSlice";
import { selectKToken,setKeycloakToken } from '../features/general/keycloakTokenSlice';
import { selectUser,setUserType } from "../features/general/usertTypeSlice";
import { selectLicense,setLicenseType } from "../features/general/licenseTypeSlice";
import { selectCf,setCfType } from "../features/general/cfTypeSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectAllChats } from "../features/chats/chatsSelector";
import ChatCard from "../features/chats/components/ChatCard";
import NewChatCard from "../features/chats/components/NewChatCard";
import UploadComponent from "../features/messages/components/UploadComponent";
import { selectChatById } from "../features/chats/chatsSelector"; // Importa il selettore
import { createUserConversation } from "../api/chatApi";
import { Navigate,useNavigate } from "react-router-dom";





const Homepage = (props) => {
 
  

  const navigate=useNavigate();
  const dispatch = useDispatch();
  var kToken=useSelector(selectKToken);
  const user = useSelector(selectUser);
  const license = useSelector(selectLicense);
  const cf = useSelector(selectCf);
  const [token,setToken] = useState();

  

  
  const chats = useSelector(selectAllChats);
  
  const orderedChats = chats.reverse();

  
 
 


  useEffect(() =>
  {
    
    
  },[chats]);


  useEffect(() =>
  {
   
    if(!props.cf)
    {
      
     
    }else
    {
    
      dispatch(setCfType(props.cf));
   
    }

   
    
    if(kToken&&user&&license)
    {
      
    dispatch(fetchChats({kToken,user,license}));
    }
   
  },[props.cf]);

  useEffect(() =>
  {
   
    if(!props.license)
    {
      dispatch(setLicenseType("free"));
     
    }else
    {
      dispatch(setLicenseType(props.license));
   
    }

   
    
    if(kToken&&user&&license)
    {
    dispatch(fetchChats({kToken,user,license}));
    }
   
  },[props.license]);

  useEffect(() =>
  {

   dispatch(setKeycloakToken(token));
  }
  
  ,[token]);

  useEffect(() =>
  {
    
   
    if(props.token)
    {
     
      if(props.token!=null)
      {
       setToken(props.token);
      }
      // dispatch(setKeycloakToken(props.token));


    }
   // dispatch(fetchChats({kToken,user,license}));
   if(kToken&&user&&license)
   {
   dispatch(fetchChats({kToken,user,license}));
   }
  },[props.token]);

  useEffect(() =>
  {
  
     if(props.user)
     {
      dispatch(setUserType(props.user));
     }
    
    
   
    if(kToken&&user&&license)
    {
  
    dispatch(fetchChats({kToken,user,license}));
    }
  
  },[props.user,token,props.license]);

  useEffect(() => {
    // Verifica se tutti e tre i parametri sono valorizzati
    if (kToken && user && license) {
      // Invoca fetchChats solo quando tutti e tre i parametri sono valorizzati
      dispatch(fetchChats({ kToken, user, license }));
    }
  }, [kToken, user, license, dispatch]);

  const handleAutoRedirect = async () => {
    // Verifica se è presente un token valido
    if (kToken&&user&&license) {
      // Cerca la prima chat che soddisfa le condizioni
      const chatToRedirect = chats.find(chat => chat.first_message === '' && chat.title === '');
      
      if (chatToRedirect) {
 
        // Esegui il redirect alla chat
         navigate(`/chat/${chatToRedirect.conversation_id}`);
        // window.location.href = `/chat/${chatToRedirect.conversation_id}`;
      } else {
  
        // Esegui la logica per creare una nuova conversazione
        // dispatch(fetchChats(kToken));
        try
        {
         const newChat = await createUserConversation(kToken,user,license);
        // dispatch(fetchChats(kToken));
        if(newChat.conversation_id)
        {
          console.log("Redirect a conversation");
        navigate(`/chat/${newChat.conversation_id}`);
        }
        } catch (error) {
          window.alert('Si è verificato un errore '+error);
         }
      }
    }
  };
  

  // const isLoading = useSelector(selectChatsLoading);
  // const error = useSelector(selectChatsError);
  

  useEffect(() => {
    if(kToken&&user&&license)
    {
    dispatch(fetchChats({kToken,user,license}));
    }
  }, [dispatch]); 

  useEffect(() => {
    // Definisci una funzione che chiama dispatch(fetchChats(kToken))
    const fetchChatsInterval = () => {

       if(kToken&&user&&license)
    {
    dispatch(fetchChats({kToken,user,license}));
    }
    };
  
    // Chiama fetchChatsInterval ogni 10 secondi
    const intervalId = setInterval(fetchChatsInterval, 10000); // 10000 millisecondi = 10 secondi
    handleAutoRedirect();
    // Pulisci l'intervallo quando il componente viene smontato
    return () => clearInterval(intervalId);
   
  }, [chats]);

  // useEffect(() => {
  //   dispatch(fetchChats());
  // }, [orderedChats]);

  return (<></>
    // <Container className="mt-4">
    //   <Row className="gy-3">
    //     <Col md={6} key={-1}>
    //       <NewChatCard token={props.token}/>
    //     </Col>
      
    //      {orderedChats.map((chat, key) => (
         
    //       <Col md={6} key={key}>
    //         <ChatCard chat={chat}></ChatCard>
    //         <ChatCardWrapper  chatId={chat.conversation_id} />
    //       </Col>
          
    //     ))} 
    //      {/* {orderedChats.map((chatId, key) => (
    //       <ChatCardWrapper key={key} chatId={chatId} />
    //     ))} */}
      
    //   </Row>
    //   {/* <Row>
    //     <h4>File</h4>
    //     <Col> <UploadComponent/> </Col>
    //   </Row> */}
    // </Container>
  );
};
const ChatCardWrapper = ({ chatId }) => {
  // Utilizzo useState per gestire lo stato locale della chat
  const [chat, setChat] = useState(null);

  // Utilizzo useSelector per ottenere la chat corrispondente all'ID
  const selectedChat = useSelector(state => selectChatById(state, chatId));

  // Utilizzo useEffect per aggiornare lo stato locale della chat quando cambia la chat selezionata
  useEffect(() => {
    setChat(selectedChat);
  }, [selectedChat]);

  // Controllo se la chat è null e renderizzo il componente ChatCard solo se la chat è definita
  return (
    <Col md={6}>
      {chatId} {selectedChat.first_message}
      {/* {chat && <ChatCard chat={chat} />} */}
    </Col>
  );
};
export default Homepage;
