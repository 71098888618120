// chatSelectors.js
import { createSelector } from "reselect";

// Basic selectors
const getChatsState = (state) => state.chats;
const getAllIds = (state) => getChatsState(state).allIds;
const getById = (state) => getChatsState(state).byId;

// Memoized selector to retrieve all chats in an array
export const selectAllChats = createSelector(
  [getAllIds, getById],
  (allIds, byId) => allIds.map((id) => byId[id])
);

// Memoized selector to retrieve a specific chat by ID
export const selectChatById = createSelector(
  [getById, (state, chatId) => chatId],
  (byId, chatId) => byId[chatId]
);

// Selector to check if chats are being loaded
export const selectActiveChat = (state) => getChatsState(state).activeChat;

// Selector to check if chats are being loaded
export const selectChatsLoading = (state) => getChatsState(state).loading;

// Selector to get any error that occurred during fetching chats
export const selectChatsError = (state) => getChatsState(state).error;
