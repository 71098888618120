import React from "react";
import { useSelector } from "react-redux";
import DocumentAccordion from "./DocumentAccordion";
import { selectAllDocuments } from "../../messages/messagesSelectors";
import { Accordion } from "react-bootstrap";
import "../../../App.css";

const DocumentsTab = () => {
  const documents = useSelector(selectAllDocuments);

 // console.log("Documents");
  //console.log(documents);
  
  
  return (
    <div >
      <Accordion defaultActiveKey={1} alwaysOpen >
        {documents
          ? documents.map((document, key) => (
              <><DocumentAccordion 
                key={key + 1}
                eventKey={key + 1}
                document={document}
              
             
              />
              </>
            ))
          : ""}
      </Accordion>
    </div>
  );
};

export default DocumentsTab;
