import { createSlice } from '@reduxjs/toolkit';

export const licenseTypeSlice = createSlice({
    name: 'licenseType',
    initialState: {
      value: "free",
    },
    reducers: {
      setLicenseType: (state, action) => {
        state.value = action.payload;
      },
    },
  });


export const { setLicenseType } = licenseTypeSlice.actions;
export const selectLicense = (state) => state.licenseType.value;
export default licenseTypeSlice.reducer;