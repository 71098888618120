import React, { useState } from "react";
import {
  Form,
  Button,
  InputGroup,
  Container,
  Card,
  Row,
  Col,
} from "react-bootstrap";
import { useEffect } from "react";
import { sendMessageToConversation } from "../../api/chatApi";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchMessages } from "../messages/messagesSlice";
import { selectMessagesEnd, selectSuggestedQuestions } from "../messages/messagesSelectors";
import { fetchChats } from "../chats/chatsSlice";
import { selectUser } from "../general/usertTypeSlice";
import { selectLicense } from "../general/licenseTypeSlice";
import { Spinner } from 'react-bootstrap';




const MessageInput = ({ kToken, user, license, chatselect, transcription }) => {
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const suggestedQuestions = useSelector(selectSuggestedQuestions);
  const end = useSelector(selectMessagesEnd);
  const { chatId } = useParams();
  const [file, setFile] = useState(null); // Stato per il file selezionato
  const base_url_upload = `${process.env.REACT_APP_HOST_ROCCO_UPLOAD}`;
  const [isEnabled, setIsEnabled] = useState(false);




  useEffect(() => {
    // Utilizza 'transcription' come desideri, ad esempio, aggiungendolo al messaggio
    setMessage(prevMessage => prevMessage + " " + transcription);
  }, [transcription]);


  const handleFileChange = (event) => {
    console.log("Clicchiamo per allegare nuovo file");
    if (event.target.files.length > 0) {
      console.log("ABBIAMO SELEZIONATO UN NUOVO FILE");
      setFile(event.target.files[0]);
      setIsEnabled(true);
      alert('Caricamento avvenuto con successo, procedi con Invia File');
    } else {
      setFile(null);
      setIsEnabled(false);
    }
    // setFile(event.target.files[0]);
  };

  const handleClickFile = () => {
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      setIsEnabled(false);
      fetch(`${base_url_upload}/upload_and_process_file`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${kToken}`,
          "user-type": `${user}`,
          "proxy_user_license": `${license}`,
        },
        body: formData
      })
        .then(responses => Promise.all([responses.ok, responses.text()])) // Restituisce un array con [success, responseText]
        .then(([success, responseText]) => {
          if (success) {
            alert('Invio avvenuto con successo');
          } else {
            throw new Error(responseText);
          }
        })
        .catch(error => {
          console.error('Errore durante il caricamento:', error);
          alert('Errore durante il caricamento');
        });
    }
  };


  const handleSendMessage = async (msg, kToken) => {
    try {

      await sendMessageToConversation(chatId, msg, kToken, user, license);

      dispatch(fetchChats({ kToken, user, license }));

      setMessage("");

    } catch (error) {
      window.alert('Si è verificato un errore '+error);
      console.error("Error sending message:", error);
    }
  };

  const handleSuggestedQuestion = async (question) => {
    setMessage(question); // Set the message state with the selected question

    await handleSendMessage(question, kToken); // Send the question as a message

  };

  useEffect(() => {

    const fetchChatsInterval = () => {
      dispatch(fetchChats({ kToken, user, license }));
    };


    const intervalId = setInterval(fetchChatsInterval, 15000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Form className="mt-auto mb-2 mx-2">
      <Row>
        {chatselect && chatselect.first_message !== "" && !end && (
          <> <h6>Generazione della risposta in corso...
            <Spinner animation="border" role="status">

            </Spinner></h6>
          </>
        )}


        {end && suggestedQuestions &&
          suggestedQuestions.map((question, key) => (
            <Col sm={4} className="mb-3" key={key}>
              <Card
                className="h-100"
                style={{ border: "2px dashed #00000020", cursor: "pointer" }}
                onClick={() => handleSuggestedQuestion(question)}
              >
                <Card.Body>
                  <Card.Title>Question {key + 1}</Card.Title>
                  {question}
                </Card.Body>
              </Card>
            </Col>
          ))}
      </Row>
      <InputGroup>
        <Form.Control
          as="textarea"
          rows={2}
          placeholder="Scrivi un messaggio..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <div>


          <Button
            variant="light"
            className="border"
            onClick={() => handleSendMessage(message, kToken)}
            title="Clicca qui per inviare la tua domanda"
          >

            <i className="bi bi-send"></i>
          </Button>
          {(license === "premium") ? (<>
            <label className="btn btn-light border" title="Clicca qui per allegare il tuo file">
              <i className="bi bi-paperclip"></i>
              <input type="file" style={{ display: 'none' }} onChange={handleFileChange} />
            </label>
            <Button
              variant={!isEnabled ? "light" : "dark"}
              className="border"
              onClick={handleClickFile}
              disabled={!isEnabled}

            >
              Invia file
            </Button>
          </>) : (<></>)}
        </div>
      </InputGroup>
    </Form>
  );
};

export default MessageInput;
