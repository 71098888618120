import { useSelector } from "react-redux";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchChatMessages } from "../../api/chatApi";
import { selectUser } from "../general/usertTypeSlice";
import { selectLicense } from "../general/licenseTypeSlice";
/**
 * Async thunk for fetching messages.
 * This can be used to fetch messages from a backend service.
 * @param {string} chatId - The ID of the chat for which to fetch messages.
 */
export const fetchMessages = createAsyncThunk(
  "messages/fetchMessages",
  async ({chatId,kToken,user,license},thunkAPI) => {
    try {
   
      // Wait for the fetchMockMessages() promise to resolve

      const response = await fetchChatMessages(chatId,kToken,user,license);

      // Assuming the response is the array of messages you want to rx  eturn
      return response;
    } catch (error) {
      // Handle any errors here
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Initial state of the messages slice
const initialState = {
  messagesById: {}, // Stores messages indexed by their IDs for quick access
  allMessagesIds: [], // Array of all message IDs to maintain insertion order
  documentsById: {},
  allDocumentsIds: [],
  suggestedQuestions: [],
  loading: false, // Loading state to indicate fetching in progress
  end: true, // Loading state to indicate fetching in progress
};

export const messagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    /**
     * Reducer to add a new message.
     * @param {object} state - The current state of the messages slice.
     * @param {object} action - The action object containing the message to add.
     */
    addMessage: (state, action) => {
      const message = action.payload;
      state.allMessagesIds.push(message.id);
      state.messagesById[message.id] = message;
    },
    resetMessage: (state) => {
      state.messagesById = {};
      state.allMessagesIds = [];
      state.documentsById = {};
      state.allDocumentsIds = [];
      state.suggestedQuestions = [];
      state.loading = false;
      state.end = false;
    },
    // Additional reducers can be added here as needed...
  },
  // Handling actions defined outside the slice, particularly async thunks.
  extraReducers: (builder) => {
    builder
      // Handle loading state at the start of an async operation.
      .addCase(fetchMessages.pending, (state) => {
     //   state.loading = true;   //SICARI LOADING
     //   state.error = null;
      })
      // Handle state changes when an async operation successfully completes.
      .addCase(fetchMessages.fulfilled, (state, action) => {
        const newMessages = action.payload.conversation[0].conversation_history;
   
        newMessages.forEach((message) => {

          if (!state.allMessagesIds.includes(message.message_id)) {
            // Add new message
          
            state.allMessagesIds.push(message.message_id);
            state.messagesById[message.message_id] = message;
            // Check end message
            if (message.message_type.toLowerCase() === "end") {
              state.end = true;
            } else {
              state.end = false;
            }
            // Process document metrics if available
            if (message.message_body) {
              if (message.message_body.questions) {
                state.suggestedQuestions = message.message_body.questions;
              }

              if ("document_metrics" in message.message_body) {
                message.message_body.document_metrics.forEach((document) => {
                  if (!state.allDocumentsIds.includes(document.document_id)) {
                    state.documentsById[document.document_id] = document;
                    state.allDocumentsIds.push(document.document_id);
                  }
                });
              }
            }
          }
        });

        state.loading = false;
        state.error = null;
      })
      // Handle state changes when an async operation fails.
      .addCase(fetchMessages.rejected, (state, action) => {
        state.loading = false; // Set loading to false on failure.
        state.error = action.error.message;
      });
  },
});

export const { addMessage, resetMessage } = messagesSlice.actions;
export default messagesSlice.reducer;
