import { useSelector } from "react-redux";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchUserChats } from "../../api/chatApi"; // Replace with your actual chat API call


/**
 * Async thunk for fetching user chats.
 */
export const fetchChats = createAsyncThunk(
  "chats/fetchChats",
  async ({kToken,user,license},thunkAPI) => {
    try {
  
      const response = await fetchUserChats(kToken,user,license);
      return response; // Assuming the response contains the array of chats
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const initialState = {
  byId: {}, // Stores chats indexed by their IDs
  allIds: [], // Array of all chat IDs
  loading: false,
  error: null,
  activeChat: null,
};

export const chatsSlice = createSlice({
  name: "chats",
  initialState,
  reducers: {
    setActiveChat: (state, action) => {
      state.activeChat = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchChats.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchChats.fulfilled, (state, action) => {
        const newChatIds = [];
        const newById = {};
        state.allIds=[];
   
        action.payload.conversations.forEach((chatId) => {
         
     
           
          if (!state.allIds.includes(chatId.conversation_id)) {
         
            newChatIds.push(chatId.conversation_id);
            newById[chatId.conversation_id] = chatId;
        
          }
        });
       
        state.allIds = [...state.allIds, ...newChatIds];
        state.byId = { ...state.byId, ...newById };
        state.loading = false;
      })
      .addCase(fetchChats.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  setActiveChat,
} = chatsSlice.actions;
export default chatsSlice.reducer;
