import React from "react";
import { Accordion, Table } from "react-bootstrap";
import { capitalizeFirstLetter } from "../../../utils/helpers";
import Markdown from "marked-react";
import "../../../App.css";
import { useSelector } from "react-redux";
import { selectUser } from "../../general/usertTypeSlice";

const DocumentAccordion = ({ eventKey, document }) => {
  console.log(document);
  const user = useSelector(selectUser);
  
  let year;
  if (document.hasOwnProperty("anno")) {
   year= document.anno;
  }
  //Verificare la presenza o meno dei dati (Diversamente crasha il FE SICARI  e sempre LDBDC)
  // const summary = capitalizeFirstLetter(document.riassunto_privacy);
  // const location = capitalizeFirstLetter(document.localita);
  // const outcome = capitalizeFirstLetter(document.esito_della_sentenza);
  // const type = capitalizeFirstLetter(document.tipo_di_sentenza);
  // const jury = capitalizeFirstLetter(document.organo_giudicante);
  //Modifica assegnazione e dichiarazione

  let summary, location, outcome, type, jury, lawReferences;

  let numero,
    data,
    rv,
    tematiche,
    presidente,
    estensore,
    relatore,
    riferimenti,
    testo;

  let titolo, dettaglio, organo, materia, sommario;

  if (document.hasOwnProperty("titolo")) {
    titolo = capitalizeFirstLetter(document.titolo);
  }

  if (document.hasOwnProperty("dettaglio")) {
    dettaglio = capitalizeFirstLetter(document.dettaglio);
  }

  if (document.hasOwnProperty("organo")) {
    organo = capitalizeFirstLetter(document.organo);
  }
  if (document.hasOwnProperty("materia")) {
    materia = capitalizeFirstLetter(document.materia);
  }
  if (document.hasOwnProperty("sommario")) {
    sommario = capitalizeFirstLetter(document.sommario);
  }

  if (document.hasOwnProperty("numero")) {
    numero = capitalizeFirstLetter(document.numero);
  }

  if (document.hasOwnProperty("data")) {
    data = capitalizeFirstLetter(document.data);
  }

  if (document.hasOwnProperty("rv")) {
    rv = capitalizeFirstLetter(document.rv);
  }

  if (document.hasOwnProperty("tematiche")) {
    tematiche = capitalizeFirstLetter(document.tematiche);
  }

  if (document.hasOwnProperty("presidente")) {
    presidente = capitalizeFirstLetter(document.presidente);
  }

  if (document.hasOwnProperty("estensore")) {
    estensore = capitalizeFirstLetter(document.estensore);
  }

  if (document.hasOwnProperty("relatore")) {
    relatore = capitalizeFirstLetter(document.relatore);
  }

  if (document.hasOwnProperty("riferimenti")) {
    riferimenti = document.riferimenti;
  }

  if (document.hasOwnProperty("testo")) {
    testo = capitalizeFirstLetter(document.testo);
  }

  if (document.hasOwnProperty("presidente")) {
    presidente = capitalizeFirstLetter(document.presidente);
  }

  if (document.hasOwnProperty("estensore")) {
    estensore = capitalizeFirstLetter(document.estensore);
  }

  if (document.hasOwnProperty("relatore")) {
    relatore = capitalizeFirstLetter(document.relatore);
  }

  if (document.hasOwnProperty("testo")) {
    testo = capitalizeFirstLetter(document.testo);
  }

  if (document.hasOwnProperty("riassunto_privacy")) {
    summary = capitalizeFirstLetter(document.riassunto_privacy);
  }

  if (document.hasOwnProperty("localita")) {
    location = capitalizeFirstLetter(document.localita);
  }

  if (document.hasOwnProperty("esito_della_sentenza")) {
    outcome = capitalizeFirstLetter(document.esito_della_sentenza);
  }

  if (document.hasOwnProperty("tipo_di_sentenza")) {
    type = capitalizeFirstLetter(document.tipo_di_sentenza);
  }

  if (document.hasOwnProperty("organo_giudicante")) {
    jury = capitalizeFirstLetter(document.organo_giudicante);
  }

  //const progressiveNum = capitalizeFirstLetter(document.progressive.toString());
  ////////////////////////////////////////////////////////////////////////////

  if (document.hasOwnProperty("riferimenti_normativi")) {
    lawReferences = capitalizeFirstLetter(document.riferimenti_normativi);
  }
  return (
    <Accordion.Item
      eventKey={eventKey}
      className={` ${
        user === "privato" ? "bg-canvas-privato" : "bg-canvas-impresa"
      }`}
    >
      <Accordion.Header>
        <div className="d-flex flex-row align-items-baseline  ">
          <strong className="me-1">[{eventKey}]</strong>
          <span>
            {year} {location}
          </span>
        </div>
      </Accordion.Header>
      <Accordion.Body>
        {lawReferences ? (
          <div className="d-flex flex-column">
            <div className="fw-bold me-1">Riferimenti normativi </div>
            <div>{lawReferences}</div>
            <div className="fw-bold me-1">Organo giudicante </div>
            <div>{jury}</div>
            <div className="fw-bold me-1">Tipo di sentenza </div>
            <div>{type}</div>
            <div className="fw-bold me-1">Esito </div>
            <div>{outcome}</div>
            <div className="fw-bold me-1">Riassunto </div>
            <Markdown>{summary}</Markdown>
          </div>
        ) : tematiche ? (
          <div className="d-flex flex-column">
            <div className="fw-bold me-1">RV {rv} </div>
            <br></br>

            <div className="fw-bold me-1">Tematiche </div>
            <div>{tematiche}</div>
            <br></br>
            <div className="fw-bold me-1">Testo </div>
            <div>{testo}</div>
            <br></br>
            <div className="fw-bold me-1">Presidente</div>
            <div>{presidente}</div>
            <div className="fw-bold me-1">Estensore</div>
            <div>{estensore}</div>
            <div className="fw-bold me-1">Relatore</div>
            <div>{relatore}</div>
          </div>
        ) : titolo ? (

       
          <div className="d-flex flex-column">
            <div className="fw-bold me-1">Titolo  </div>
            <div>{titolo}</div>

            <div className="fw-bold me-1">Dettaglio </div>
            <div>{dettaglio}</div>
            <br></br>
            <div className="fw-bold me-1">Organo </div>
            <div>{organo}</div>
            <br></br>
            <div className="fw-bold me-1">Materia : </div>
            <div>{materia}</div>
         
            <div className="fw-bold me-1">Sommario: </div>
            <div>{sommario}</div>
          </div>
        ) : (
          <></>
        )}
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default DocumentAccordion;
