import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";

import { Offcanvas } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import {
    selectShowRightSidebar,
    toggleRightSidebar,
  } from "../../features/rightSidebar/rightSidebarSlice";



import LeftSidebar from "./LeftSidebar";



const LeftSidebarCanvas = () => {
    const showRightSidebar = useSelector(selectShowRightSidebar);
    const handleClose = () => {
        dispatch(toggleRightSidebar());
      };
     
    
  
    const dispatch = useDispatch();
    const showSidebar = useSelector(selectShowRightSidebar);



    return (
        <>
        <Offcanvas show={showSidebar} onHide={handleClose} placement="start"  style={{ overflowY: "auto", maxHeight: "100vh", width: "50vw" }}>
            <div style={{ overflowY: "auto", maxHeight: "100vh", width: "50vw" }}>
                <LeftSidebar />
            </div>
        </Offcanvas></>
    );
};

export default LeftSidebarCanvas;
