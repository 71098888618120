import React from "react";
import MessageCard from "./MessageCard";
import ChainOfThought from "./ChainOfThought";
import { useSelector } from "react-redux";
import { selectUser } from "../../general/usertTypeSlice";

const BotMessage = React.memo(({ messageBody,timeStamp }) => {
  const user = useSelector(selectUser);
 
  console.log(messageBody.message);

  console.log(messageBody.chain_of_thought[0]);

  return (
    <div>
      {messageBody.chain_of_thought ? (
        // Render this if 'myProperty' exists in 'data'chain_of_thought
        <ChainOfThought chain={messageBody.chain_of_thought}></ChainOfThought>
      ) : (
        ""
      )}
      { (user=="privato") ? (
      <MessageCard
        messageBody={messageBody}
        timeStamp={timeStamp}
        sender={"Tutor di famiglia"}
        position={"start"}
      ></MessageCard>
      ) : (<>
        <MessageCard
        messageBody={messageBody}
        timeStamp={timeStamp}
        sender={"Tutor d'impresa"}
        position={"start"}
      ></MessageCard>
      
      
      </>) }
      
    </div>
  );
});

export default BotMessage;
