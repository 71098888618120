import { useSelector } from "react-redux";
import "../../../App.css";
import { selectUser } from "../../general/usertTypeSlice";
import Markdown from "marked-react";
import React from "react";
import { Card } from "react-bootstrap";
import { capitalizeFirstLetter } from "../../../utils/helpers";

const Thought = React.memo(({ thought }) => {
  const user= useSelector(selectUser);
  const toolInput = capitalizeFirstLetter(thought.tool_input);
  return (
    <div className="d-flex justify-content-start"  >
      <Card className={` ${user === "privato" ? "bg-dd-privato" : "bg-dd-impresa"}`} >
        <Card.Body>
          <Card.Title>{thought.tool}</Card.Title>
          <ul>
            <li>
              <b>Input</b> <Markdown>{toolInput}</Markdown>
            </li>
            <li>
              <b>Osservazioni</b> <Markdown>{thought.observation}</Markdown>
            </li>
          </ul>
        </Card.Body>
      </Card>
    </div>
  );
});

export default Thought;
