import "../../App.css";
import React, { useEffect, useState } from "react";
import { Offcanvas, ListGroup, Button } from "react-bootstrap";
import {
  
  setRightSidebarVisibility,
  toggleRightSidebar,
} from ".././rightSidebar/rightSidebarSlice";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import { fetchChats } from "../chats/chatsSlice";
import { selectKToken } from "../general/keycloakTokenSlice";
import { selectAllChats } from "../chats/chatsSelector";
import { selectUser } from "../general/usertTypeSlice";
import { selectLicense } from "../general/licenseTypeSlice";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

import { deleteConversation } from "../../api/chatApi";


const LeftSidebar = () => {
  const kToken = useSelector(selectKToken);
  const user= useSelector(selectUser);
  const license= useSelector(selectLicense);

  
  const dispatch = useDispatch();
  const handleDeleteConversation = async (id) => {
    try {
      const response = await deleteConversation(id, kToken);
      dispatch(fetchChats({kToken,user,license}));
    } catch (error) {
      console.error("Error creating conversation:", error);
      // Handle error appropriately (show error message, etc.)
    }
  };
  const handleClose = () => {
    dispatch(toggleRightSidebar());
  };
  const resetSidebar = () => {
    dispatch(setRightSidebarVisibility(false));
  };

  const chats = useSelector(selectAllChats);
  const token = useSelector(selectKToken);

  useEffect(() => {
    if (token&&user&&license) {
      dispatch(fetchChats({kToken,user,license}));
    }
  }, [dispatch]);

  useEffect(() => {

  }, [chats]);


  return (

    <div style={{width:"100%"}}>
      {/* <Offcanvas show={showSidebar} onHide={handleClose} placement="start">
      */}
      {/* <Offcanvas.Header closeButton>
        <Offcanvas.Title> */}
      <div style={{width:"100%"}}>
     


      <Button   className={` ${user === "privato" ? "bg-privato" : "bg-impresa"}`}
 
        as={Link}
        to={`/`}
        onClick={resetSidebar}
      >
        <FontAwesomeIcon icon={faComment} className="me-2" />
        Nuova Chat
      </Button>
      </div>
      {/*  </Offcanvas.Title>
      </Offcanvas.Header>  
      <Offcanvas.Body>*/}
      {/* <b>Le tue precedenti Chat</b> */}
      <ListGroup >
        {chats.map((chat) => (
          chat.first_message !== '' && (
            <ListGroup.Item
              key={chat.conversation_id}
              action
              as={Link}
              to={`/chat/${chat.conversation_id}`}
              onClick={resetSidebar}
              className={` ${user === "privato" ? "bg-canvas-privato" : "bg-canvas-impresa"}`}
            >
              <div >
               <table>
               
                <tr><font style={{ fontSize: '12px' }}><b>{chat.first_message}</b></font></tr>
                <tr><font style={{ fontSize: '10px' }}>{chat.title}</font></tr>

                
                </table> <button className="btn  btn-sm ms-2" onClick={() => handleDeleteConversation(chat.conversation_id)}>
                  <FontAwesomeIcon icon={faTrash} />
                  
                </button></div>
            </ListGroup.Item>
          )
        ))}
      </ListGroup>
      {/* </Offcanvas.Body> */}
      {/* </Offcanvas> */}
    </div>

  );
};

export default LeftSidebar;
