import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import DocumentsTab from "./components/DocumentsTab";
import UploadComponent from "../messages/components/UploadComponent";
import { Offcanvas } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import {
  selectShowLeftSidebar,
  setLeftSidebarVisibility,
  toggleLeftSidebar,
} from "../leftSidebar/leftSidebarSlice";

const RightSidebar = () => {
  const showSidebar = useSelector(selectShowLeftSidebar);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(toggleLeftSidebar());
  };
  const resetSidebar = () => {
    dispatch(setLeftSidebarVisibility(false));
  };
  const [key, setKey] = useState("sentences");

  return (
    <Offcanvas show={showSidebar} onHide={handleClose} placement="end"  style={{ overflowY: "auto", maxHeight: "100vh", width: "50vw" }}>
      <div style={{ overflowY: "auto", maxHeight: "100vh",width: "50vw" }}>
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3 mt-2"
        >
          <Tab eventKey="sentences" title="Sentenze">
            {/* DocumentsTab component should be renamed accordingly following "sentences" */}
            <DocumentsTab />
          </Tab>
          {/* <Tab eventKey="cicero" title="Cicero">
    
     
          </Tab> */}
          <Tab eventKey="documents" title="Documenti">
            <UploadComponent /> 
          </Tab>
        </Tabs>
      </div>
    </Offcanvas>
  );
};

export default RightSidebar;
