import { useSelector } from 'react-redux';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Toast from 'react-bootstrap/Toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHammer } from '@fortawesome/free-solid-svg-icons';
import { selectUser } from '../../general/usertTypeSlice';


function LogoToast() {
  const user = useSelector(selectUser);
  const [showA, setShowA] = useState(false);
  const [showB, setShowB] = useState(true);

  const toggleShowA = () => setShowA(!showA);
  const toggleShowB = () => setShowB(!showB);

  return (
    <Row>
      <Col md={6} className="mb-2">
        {/* <Button
          onClick={toggleShowA}
          className="mb-2 d-flex align-items-center bg-warning"
          style={{ border: 'none' }}
        >
           <FontAwesomeIcon icon={faHammer} style={{ color: 'black' }} />
          
           Avv.Rocco 
           <strong> Ver 1.0</strong> 
          
           </Button> */}
        <Button
          onClick={toggleShowA}
          className={`mb-2 d-flex align-items-center ${user === "privato" ? "bg-privato" : "bg-impresa"}`}
          style={{ border: 'none' }}
        >
          <FontAwesomeIcon icon={faHammer} style={{ color: 'black' }} />
          Avv.Rocco <strong> Ver 1.0</strong>
        </Button>
        {/* <Toast show={showA} onClose={toggleShowA} className="bg-warning">
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">Passa a Premium</strong>
            <small>+10% Crediti Gratuiti</small>
          </Toast.Header>
          <Toast.Body>Passa alla Versione Premium (pulsante)</Toast.Body>
        </Toast> */}
      </Col>

    </Row>
  );
}

export default LogoToast;