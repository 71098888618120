import { createSlice } from '@reduxjs/toolkit';

export const userTypeSlice = createSlice({
    name: 'userType',
    initialState: {
      value: "privato",
    },
    reducers: {
      setUserType: (state, action) => {
        state.value = action.payload;
      },
    },
  });


export const { setUserType } = userTypeSlice.actions;
export const selectUser = (state) => state.userType.value;
export default userTypeSlice.reducer;