import { useSelector } from 'react-redux';

const user_id = "12345";


const base_url = `${process.env.REACT_APP_HOST_ROCCO}`;
const base_url_upload = `${process.env.REACT_APP_HOST_ROCCO_UPLOAD}`;


 
/**
 * Fetches the user's chat conversations.
 * @returns A promise resolving to the list of conversations.
 */
export const fetchUserChats = async (token,user,license) => {
 
 
 
 
  const response = await fetch(`${base_url}/conversations/`, {
    method: "GET", // Specify the HTTP method
    headers: {
      "Content-Type": "application/json",
      "user-type": `${user}`,
      "proxy_user_license": `${license}`,
      'Authorization': `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  return response.json();
};

/**
 * Fetches the messages for a specific chat conversation.
 * @param {string} chatId - The ID of the chat conversation.
 * @param {string} kToken 
 * @returns A promise resolving to the messages of the conversation.
 */
export const fetchChatMessages = async (chatId,kToken,user,license) => {

  const response = await fetch(`${base_url}/conversations/${chatId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json", 'Authorization': `Bearer ${kToken}`,
      "user-type": `${user}`,
      "proxy_user_license": `${license}`,
    },
   
  });

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  return response.json();
};

/**
 * Creates a new conversation.
 * @param {object} conversationData - The data for the new conversation.
 * @returns A promise resolving to the created conversation.
 */
export const createUserConversation = async (token,user,license) => {
 
  const response = await fetch(`${base_url}/conversations/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json", 'Authorization': `Bearer ${token}`,
      "user-type": `${user}`,
      "proxy_user_license": `${license}`,

    },
  });

  if (!response.ok) {
    const errorData = await response.json();
    console.log(errorData); // Stampa i dati dell'errore per il debug
    const errorMessage = errorData && errorData.detail ? errorData.detail : "Errore sconosciuto";
    throw new Error(errorMessage);
  }

  return response.json();
};

/**
 * Sends a new message to a specific conversation.
 * @param {string} conversationId - The ID of the conversation.
 * @param {string} userPrompt - The message to be sent.
 * @returns A promise resolving to the response of the message sent.
 */
export const sendMessageToConversation = async (conversationId, userPrompt,token,user,license) => {
  const response = await fetch(
    `${base_url}/conversations/${conversationId}/user`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",'Authorization': `Bearer ${token}`,
         "user-type": `${user}`,
         "proxy_user_license": `${license}`,
      },
      body: JSON.stringify({
        query: userPrompt,
      }),
    }
  );

  if (!response.ok) {
    const errorData = await response.json();
    console.log(errorData); // Stampa i dati dell'errore per il debug
    const errorMessage = errorData && errorData.detail ? errorData.detail : "Errore sconosciuto";
    throw new Error(errorMessage);
  }

  return response.json();
};

/**
 * Deletes a specific conversation.
 * @param {string} conversationId - The ID of the conversation to be deleted.
 * @returns A promise resolving to the response of the deletion request.
 */
export const deleteConversation = async (conversationId,token,user,license) => {
  const response = await fetch(`${base_url}/conversations/${conversationId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",'Authorization': `Bearer ${token}`,
      "user-type": `${user}`,
      "proxy_user_license": `${license}`,
    },
  });

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  return response.json();
};
