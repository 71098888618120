// messageSelectors.js
import { createSelector } from "reselect";

// Basic selectors
const getMessagesState = (state) => state.messages;
const getAllMessagesIds = (state) => getMessagesState(state).allMessagesIds;
const getMessagesById = (state) => getMessagesState(state).messagesById;
const getAllDocumentsIds = (state) => getMessagesState(state).allDocumentsIds;
const getDocumentsById = (state) => getMessagesState(state).documentsById;

// MESSAGES

// Memoized selector to retrieve all messages in an array
export const selectAllMessages = createSelector(
  [getAllMessagesIds, getMessagesById],
  (allMessagesIds, messagesById) => allMessagesIds.map((id) => messagesById[id])
);

// Memoized selector to retrieve a specific message by ID
export const selectMessageById = createSelector(
  [getMessagesById, (state, messageId) => messageId],
  (messagesById, messageId) => messagesById[messageId]
);

// DOCUMENTS

// Memoized selector to retrieve all documents in an array
export const selectAllDocuments = createSelector(
  [getAllDocumentsIds, getDocumentsById],
  (allDocumentsIds, documentsById) =>
    allDocumentsIds.map((id) => documentsById[id])
);

// Memoized selector to retrieve a specific documents by ID
export const selectDocumentsById = createSelector(
  [getDocumentsById, (state, messageId) => messageId],
  (documentsById, messageId) => documentsById[messageId]
);

// Memoized selector to retrieve all suggested questions
export const selectSuggestedQuestions = (state) => getMessagesState(state).suggestedQuestions;

// Selector to check if messages are being loaded
export const selectMessagesLoading = (state) => getMessagesState(state).loading;

// Selector to check if last message is an end message
export const selectMessagesEnd = (state) => getMessagesState(state).end;

// Selector to get any error that occurred during fetching messages
export const selectMessagesError = (state) => getMessagesState(state).error;
