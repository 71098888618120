import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Button } from "react-bootstrap";
import LeftSidebar from "../features/leftSidebar/LeftSidebar";
import LeftSidebarCanvas from "../features/leftSidebar/LeftSideBarCanvas";
import {
  selectShowLeftSidebar,
  toggleLeftSidebar,
} from "../features/leftSidebar/leftSidebarSlice";
import { useState } from "react";
import { keycloakTokenSlice, selectKToken, setKeycloakToken } from "../features/general/keycloakTokenSlice";
import { useParams } from "react-router-dom";
import RightSidebar from "../features/rightSidebar/RightSidebar";
import {
  selectShowRightSidebar,
  toggleRightSidebar,
} from "../features/rightSidebar/rightSidebarSlice";
import MessageInput from "../features/messageInput/MessageInput";
import SpeechRecognitionComponent from "../features/messageInput/SpeechRecognitionComponent";
import Card from 'react-bootstrap/Card';
import LogoToast from "../features/chats/components/LogoToast";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { faAlignCenter, faHouse } from '@fortawesome/free-solid-svg-icons';
import { selectChatById } from "../features/chats/chatsSelector";
import Logo from '../images/roccoimpresa.png';
import LogoPrivato from '../images/roccoprivato.png';
import "../App.css";
import { selectUser } from "../features/general/usertTypeSlice";
import { selectLicense } from "../features/general/licenseTypeSlice";
import { selectCf } from "../features/general/cfTypeSlice";
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { useNavigate } from "react-router-dom";



// import RightOffcanvas from "../components/RightOffcanvas";

const ChatLayout = ({ children }) => {
  const { chatId } = useParams(); // Extract chatId from the URL
  const showLeftSidebar = useSelector(selectShowLeftSidebar);
  const showRightSidebar = useSelector(selectShowRightSidebar);
  const kToken = useSelector(selectKToken);
  const User = useSelector(selectUser);
  const License = useSelector(selectLicense);
  const cf = useSelector(selectCf);
  const chatselect = useSelector((state) => selectChatById(state, chatId));
  const [showButton, setShowButton] = useState(false);
  const [remainingMessages, setRemainingMessages] = useState(null);
  const hostdash = process.env.REACT_APP_HOST_DASHBOARD;

  const hostrocco = process.env.REACT_APP_HOST_ROCCO;
  const hostauth_be = process.env.REACT_APP_HOST_AUTH;
  const navigate = useNavigate();

  const base_url_license = `${process.env.REACT_APP_HOST_LICENSE}`;
  const [isDesktop, setIsDesktop] = useState(window.matchMedia('(min-width: 768px)').matches);
  //Gestione Media Query
  useEffect(() => {

    const mediaQuery = window.matchMedia('(min-width: 768px)');
    const handler = (event) => setIsDesktop(event.matches);

    mediaQuery.addListener(handler);
    return () => mediaQuery.removeListener(handler);
  }, []);


  const [transcription, setTranscription] = useState("");

  const handleTranscriptionChange = (newTranscription) => {
    setTranscription(newTranscription);
  };




  const buttonStyle = {
    border: 'none',
    background: 'transparent',
    cursor: 'pointer',
    outline: 'none',
  };

  useEffect(() => {
    const fetchCredits = async () => {
      try {
        const response = await fetch(`${base_url_license}/license/queries`, {
          headers: {
            'Authorization': `Bearer ${kToken}`,
            'user-type': User
          }
        });
        if (response.ok) {
          const data = await response.json();
          setRemainingMessages(data.remaining_messages);
        } else {
          console.error('Errore durante il recupero dei crediti');
        }
      } catch (error) {
        console.error('Errore durante la richiesta dei crediti:', error);
      }
    };
    console.log(kToken);
    if (kToken == null) {
      console.log("Navigate verso la root");
      navigate("/");
    }
    fetchCredits();
  }, [kToken, User]); //

  useEffect(() => {
    // Aggiungi un listener per lo scroll per mostrare/nascondere il pulsante
    const handleScroll = () => {

      if (window.scrollY > window.innerHeight / 2) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }

    };
    window.addEventListener('scroll', handleScroll);

    // Pulisci il listener quando il componente viene smontato
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth'
    });
  };
  const generateSessionId = () => {

    const timestamp = Date.now().toString();

    const id = CryptoJS.SHA1(timestamp).toString();
    return id;
  };

  const registerSession = async (token, user, cf, license) => {
    try {

      const id = generateSessionId();


      // const token = 'your_token';
      // const user = 'user_value';
      // const cf = 'cf_value';
      // const license = 'license_value';

      const response = await axios.post(`${hostauth_be}/session`, {
        id: id,
        token: token,
        user: user,
        cf: cf,
        license: license
      });

      console.log('Session registered successfully:', response.data);
      return id;
    } catch (error) {
      console.error('Error registering session:', error);
      return null;
    }
  };
  const registerSessionOnClick = async () => {
    try {
      const sessionId = await registerSession(kToken, User, cf, License);
      if (sessionId) {
        // Esegui la logica desiderata dopo aver registrato correttamente la sessione
        //  console.log('Session ID:', sessionId);

        // Qui puoi aprire il link utilizzando solo l'ID della sessione
        window.location.href = `${hostdash}/dashboard?sessionId=${sessionId}`;

      } else {
        console.error('Failed to register session');
      }
    } catch (error) {
      console.error('Error registering session:', error);
    }
  };

  const dispatch = useDispatch();

  return (
    <>
      <Container fluid style={{ textAlign: "left", height: "100%" }}>

        <Row className="h-100">




          {showRightSidebar && (

            <Col md={showRightSidebar ? 3 : 0}  style={{ background: 'white' }} >
              {/* <div className={"left-sidebar my-custom-scrollbar"}> */}
              <div className={`left-sidebar ${isDesktop ? 'my-custom-scrollbar' : ''}`} style={{ maxWidth: "50vw" }}>
                {isDesktop ? <LeftSidebar /> : <LeftSidebarCanvas />}
              </div>
            </Col>
          )}
          <Col md={showRightSidebar ? 9 : 12} className="chat-col" style={{ textAlign: "left", height: "100%" }}>

            <div className="d-flex flex-row justify-content-between align-items-start" style={{ textAlign: "left", height: "100%", maxWidth: '100%', wordWrap: 'break-word', overflowWrap: 'break-word', }}>


              {/* <h6 className="mt-2">Chat {chatId}</h6> */}
              <div style={{ textAlign: "left", height: "100%" }}>


                {!chatselect ? (<></>

                  // < >

                  //   <div  >
                  //     {User === "azienda" && <img src={Logo} style={{ width: '150px' }} alt="Logo Azienda" />}
                  //     {User === "privato" && <img src={LogoPrivato} style={{ width: '150px' }} alt="Logo Privato" />}
                  //     <img src={Logo} style={{ width: '150px' }} />   <img src={Logo} style={{ width: '150px' }} /> <h1>prova</h1>

                  //     <div>

                  //       <h3>Come posso aiutarti? </h3><br></br>
                  //       <b>Puoi formulare la tua domanda come negli esempi di seguito riportati</b>
                  //       <br></br>
                  //       <ul>

                  //         <li>Ottieni risposte precise su quesiti amministrativi e legali</li>
                  //         <li>Carica documentazione personale</li>
                  //         <li>Interpreta e crea documenti in pochi secondi (Versione Premium)</li>
                  //       </ul>
                  //       <br></br>
                  //     </div>
                  //     <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  //       {User === "azienda" ? (<>
                  //         <Card >
                  //           <Card.Body>

                  //             <Card.Title>Esempio 1</Card.Title>
                  //             <Card.Subtitle className="mb-2 text-muted">Domande in ambito di diritto di famiglia</Card.Subtitle>
                  //             <Card.Text>
                  //               "Come posso procedere con un divorzio e quali sono i miei diritti relativi alla custodia dei figli e alla divisione dei beni coniugali?"
                  //             </Card.Text>
                  //             {/* <Card.Link href="#"
                  //       style={{ color: 'black' }}
                  //     ><b>Esegui >></b></Card.Link> */}

                  //           </Card.Body>
                  //         </Card>
                  //         <Card >
                  //           <Card.Body>
                  //             <Card.Title>Esempio 2</Card.Title>
                  //             <Card.Subtitle className="mb-2 text-muted">Domande relative a controversie contrattuali</Card.Subtitle>
                  //             <Card.Text>
                  //               "Ho firmato un contratto che ora ritengo ingiusto o che non è stato rispettato dall'altra parte. Quali sono le mie opzioni legali per risolvere questa disputa?"
                  //             </Card.Text>


                  //           </Card.Body>
                  //         </Card>
                  //         <Card >
                  //           <Card.Body>
                  //             <Card.Title>Esempio 3</Card.Title>
                  //             <Card.Subtitle className="mb-2 text-muted">Domande su successioni e testamenti</Card.Subtitle>
                  //             <Card.Text>
                  //               "Come posso redigere un testamento valido? E in caso di decesso di un familiare, quali sono le procedure legali per la gestione dell'eredità?"
                  //             </Card.Text>


                  //           </Card.Body>
                  //         </Card></>
                  //       ) :
                  //         (
                  //           <>
                  //             <Card>
                  //               <Card.Body>
                  //                 <Card.Title>Esempio 1</Card.Title>
                  //                 <Card.Subtitle className="mb-2 text-muted">Esempio Amministrativo</Card.Subtitle>
                  //                 <Card.Text>
                  //                   "Priorità per la redazione della scheda tecnica: confronto punteggio tecnico e linee guida"
                  //                 </Card.Text>

                  //               </Card.Body>
                  //             </Card>
                  //             <Card >
                  //               <Card.Body>
                  //                 <Card.Title>Esempio 2</Card.Title>
                  //                 <Card.Subtitle className="mb-2 text-muted">Esempio Giuridico</Card.Subtitle>
                  //                 <Card.Text>
                  //                   "Diritti di tutela per società di minoranza in assemblea per contenzioso con fornitore"
                  //                 </Card.Text>


                  //               </Card.Body>
                  //             </Card>
                  //             <Card >
                  //               <Card.Body>
                  //                 <Card.Title>Esempio 3</Card.Title>
                  //                 <Card.Subtitle className="mb-2 text-muted">Esempio Contabile</Card.Subtitle>
                  //                 <Card.Text>
                  //                   "Trattamento contabile dei contributi statali per R&S: principi e regole"
                  //                 </Card.Text>


                  //               </Card.Body>
                  //             </Card></>
                  //         )
                  //       }
                  //     </div>
                  //   </div>
                  // </>

                ) : chatselect.first_message === "" ? (
                  <div >
                    <div style={{ align: "center" }}>
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ align: "left" }}>
                          <Button
                            variant=""
                            onClick={() => dispatch(toggleRightSidebar())}
                            className="me-2"
                          >
                            {showRightSidebar ? (
                              <i className="bi bi-x text-muted"></i>
                            ) : (
                              <i className="bi bi-file-text text-muted"></i>
                            )}
                          </Button>
                          {User === "azienda" && <img src={Logo} style={{ width: '150px' }} alt="Logo Azienda" />}
                          {User === "privato" && <img src={LogoPrivato} style={{ width: '150px' }} alt="Logo Privato" />}
                          {/* <img src={Logo} style={{width:'150px'}}/> */}
                        </div>
                        <div style={{ align: "right" }}>

                          <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
                            <button style={buttonStyle} onClick={registerSessionOnClick} title="Torna alla Home">
                              <div >
                                {User === "privato" ? (
                                  <FontAwesomeIcon icon={faHouse} size="2x" className={"privato"} />) : (
                                  <FontAwesomeIcon icon={faHouse} size="2x" className={"impresa"} />
                                )}
                                <br></br>

                                {remainingMessages !== null ? (
                                  <p>Crediti {remainingMessages}</p>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </button>

                            <Button
                              variant=""
                              onClick={() => dispatch(toggleLeftSidebar())}
                              className="me-2"
                            >
                              {showLeftSidebar ? (
                                <i className="bi bi-x"></i>
                              ) : (
                                <i className="bi bi-list"></i>
                              )}
                            </Button>

                          </div>
                        </div>
                      </div>
                      <div>

                        <h3>Come posso aiutarti? </h3><br></br>
                        <b>Puoi formulare la tua domanda come negli esempi di seguito riportati</b>
                        <ul>

                          <li>Ottieni risposte precise su quesiti amministrativi e legali</li>
                          <li>Carica documentazione personale</li>
                          <li>Interpreta e crea documenti in pochi secondi (Versione Premium)</li>
                        </ul>
                        <br></br>
                      </div>
                      {User === "privato" && (
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                          <Card >
                            <Card.Body>
                              <Card.Title>Esempio 1</Card.Title>
                              <Card.Subtitle className="mb-2 text-muted">Domande in ambito di diritto di famiglia</Card.Subtitle>
                              <Card.Text>
                                "Come posso procedere con un divorzio e quali sono i miei diritti relativi alla custodia dei figli e alla divisione dei beni coniugali?"
                              </Card.Text>
                              {/* <Card.Link href="#"
                      style={{ color: 'black' }}
                    ><b>Esegui >></b></Card.Link> */}

                            </Card.Body>
                          </Card>
                          <Card>
                            <Card.Body>
                              <Card.Title>Esempio 2</Card.Title>
                              <Card.Subtitle className="mb-2 text-muted">Domande relative a controversie contrattuali</Card.Subtitle>
                              <Card.Text>
                                "Ho firmato un contratto che ora ritengo ingiusto o che non è stato rispettato dall'altra parte. Quali sono le mie opzioni legali per risolvere questa disputa?"
                              </Card.Text>
                              {/* <Card.Link href="#"
                      style={{ color: 'black' }}
                    ><b>Esegui >></b></Card.Link> */}

                            </Card.Body>
                          </Card>
                          <Card>
                            <Card.Body>
                              <Card.Title>Esempio 3</Card.Title>
                              <Card.Subtitle className="mb-2 text-muted">Domande su successioni e testamenti</Card.Subtitle>
                              <Card.Text>
                                "Come posso redigere un testamento valido? E in caso di decesso di un familiare, quali sono le procedure legali per la gestione dell'eredità?"
                              </Card.Text>
                              {/* <Card.Link href="#"
                      style={{ color: 'black' }}
                    ><b>Esegui >></b></Card.Link> */}

                            </Card.Body>
                          </Card>
                        </div>
                      )}
                      {User === "azienda" && (
                        // Esempi domande
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                          <Card >
                            <Card.Body>
                              <Card.Title>Esempio 1</Card.Title>
                              <Card.Subtitle className="mb-2 text-muted">Esempio Amministrativo</Card.Subtitle>
                              <Card.Text>
                                "Priorità per la redazione della scheda tecnica: confronto punteggio tecnico e linee guida"
                              </Card.Text>

                            </Card.Body>
                          </Card>
                          <Card >
                            <Card.Body>
                              <Card.Title>Esempio 2</Card.Title>
                              <Card.Subtitle className="mb-2 text-muted">Esempio Giuridico</Card.Subtitle>
                              <Card.Text>
                                "Diritti di tutela per società di minoranza in assemblea per contenzioso con fornitore"
                              </Card.Text>


                            </Card.Body>
                          </Card>
                          <Card>
                            <Card.Body>
                              <Card.Title>Esempio 3</Card.Title>
                              <Card.Subtitle className="mb-2 text-muted">Esempio Contabile</Card.Subtitle>
                              <Card.Text>
                                "Trattamento contabile dei contributi statali per R&S: principi e regole"
                              </Card.Text>


                            </Card.Body>
                          </Card>
                        </div>
                      )}
                    </div>
                  </div>

                )


                  : (<div >
                    <div style={{ align: "center" }}>
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ align: "left" }}>
                          <Button
                            variant=""
                            onClick={() => dispatch(toggleRightSidebar())}
                            className="me-2"
                          >
                            {showRightSidebar ? (
                              <i className="bi bi-x text-muted"></i>
                            ) : (
                              <i className="bi bi-file-text text-muted"></i>
                            )}
                          </Button>
                          {User === "azienda" && <img src={Logo} style={{ width: '150px' }} alt="Logo Azienda" />}
                          {User === "privato" && <img src={LogoPrivato} style={{ width: '150px' }} alt="Logo Privato" />}
                          {/* <img src={Logo} style={{width:'150px'}}/> */}
                        </div>
                        <div style={{ align: "right" }}>

                          <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
                            <button style={buttonStyle} onClick={registerSessionOnClick} title="Torna alla Home">
                              <div >
                                {User === "privato" ? (
                                  <FontAwesomeIcon icon={faHouse} size="2x" className={"privato"} />) : (
                                  <FontAwesomeIcon icon={faHouse} size="2x" className={"impresa"} />
                                )}
                                <br></br>

                                {remainingMessages !== null ? (
                                  <p>Crediti {remainingMessages}</p>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </button>

                            <Button
                              variant=""
                              onClick={() => dispatch(toggleLeftSidebar())}
                              className="me-2"
                            >
                              {showLeftSidebar ? (
                                <i className="bi bi-x"></i>
                              ) : (
                                <i className="bi bi-list"></i>
                              )}
                            </Button>

                          </div>
                        </div>
                      </div>


                    </div>
                  </div>)}
                {/* //Messagemanager */}
                <div style={{
                  maxWidth: '99%', // Imposta la larghezza massima desiderata
                  wordWrap: 'break-word', // Per supportare vecchi browser
                  overflowWrap: 'break-word', // Per supportare browser moderni
                }}>
                  <div className={`scroll-to-bottom-container`}>
                    <button style={buttonStyle} className={`scroll-to-bottom-btn ${showButton ? 'show' : ''}`}
                      onClick={scrollToBottom}>
                      <FontAwesomeIcon icon={faArrowDown} className="arrow-icon" />
                    </button>
                  </div>

                  <div style={{ bottom: 0 }}>
                    <Row>

                      <Col>
                        {children}
                        <div style={{ height: "30px" }}></div>


                        <MessageInput kToken={kToken} user={User} license={License} chatselect={chatselect} transcription={transcription} />
                        <SpeechRecognitionComponent onTranscriptChange={handleTranscriptionChange} />
                      </Col>
                    </Row>
                  </div>
                  <center><br></br><br></br><font size="1">Tutti i diritti riservati.<br /> Questo sito web e il suo contenuto, inclusi testi, immagini, grafici e ogni altro materiale, sono protetti dalle leggi sul diritto d\'autore e sono di proprietà esclusiva del detentore dei diritti. È vietata qualsiasi forma di riproduzione, distribuzione, comunicazione pubblica, trasformazione o qualsiasi altro atto di sfruttamento del materiale presente sul sito, senza l'esplicita autorizzazione scritta del proprietario dei diritti.<br /> Diritti d'autore registrati presso SIAE con numero di protocollo D000022865.</font>
                  </center>
                </div>
              </div>

            </div>

          </Col>

        </Row>
        <RightSidebar />
        {/* <LeftSidebar /> */}
      </Container>
    </>
  );
};

export default ChatLayout;
