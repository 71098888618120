import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrophone, faMicrophoneSlash } from "@fortawesome/free-solid-svg-icons";
import "./SpeechRecognition.css"; // File CSS per l'animazione
import { Button } from "react-bootstrap";
const SpeechRecognitionComponent = ({ onTranscriptChange }) => {
  const [transcript, setTranscript] = useState("");
  const [isListening, setIsListening] = useState(false);
  const [recognition, setRecognition] = useState(null);
  const [shouldStop, setShouldStop] = useState(false); // Flag per interrompere la registrazione

  useEffect(() => {
    if (!("webkitSpeechRecognition" in window)) {
      alert("Il tuo browser non supporta la Web Speech API.");
      return;
    }

    const recognitionInstance = new window.webkitSpeechRecognition();

    recognitionInstance.continuous = true;

    recognitionInstance.onstart = () => {
     
      setIsListening(true);
    };

    recognitionInstance.onend = () => {
   
      setIsListening(false);
      
      // Se il flag shouldStop è impostato su true, interrompi la registrazione
      if (shouldStop) {
        setShouldStop(false); // Ripristina il flag
        recognitionInstance.stop();
      }
    };

    recognitionInstance.onresult = (event) => {
      const currentTranscript = event.results[event.results.length - 1][0].transcript;
     
      
      // Verifica se la trascrizione è "invia messaggio"
      if (currentTranscript.toLowerCase().includes("invia messaggio")) {
        setShouldStop(true); // Imposta il flag per interrompere la registrazione
      } else {
        setTranscript(prevTranscript => prevTranscript + " " + currentTranscript);
        onTranscriptChange && onTranscriptChange(currentTranscript); 
      }
    };

    recognitionInstance.onerror = (event) => {
      console.error("Errore durante il riconoscimento vocale:", event.error);
    };

    setRecognition(recognitionInstance);

    return () => {
      if (recognitionInstance) {
        recognitionInstance.stop();
      }
    };
  }, [shouldStop]); // Aggiungi shouldStop come dipendenza dell'effetto

  // Effetto per ripristinare shouldStop quando la trascrizione cambia
  useEffect(() => {
    setShouldStop(false);
  }, [transcript]);

  const startListening = () => {
    console.log("Avvio trascrizione");
    if (recognition) {
      recognition.start();
    }
  };

  const stopListening = () => {
    console.log("Stop trascrizione");
    if (recognition) {
      recognition.stop();
    }
  };

  return (
   
    <Button
    variant="light"
    className={`border ${isListening ? 'recording-button' : ''}`}
    onClick={isListening ? stopListening : startListening}
    disabled={false}
  >
    <FontAwesomeIcon icon={isListening ? faMicrophoneSlash : faMicrophone} className="microphone-icon" />
  </Button>
  );
};

export default SpeechRecognitionComponent;
