import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import RightSidebar from "../features/rightSidebar/RightSidebar";
import { useDispatch, useSelector } from "react-redux";
import { selectShowRightSidebar } from "../features/rightSidebar/rightSidebarSlice";
import {
  selectShowLeftSidebar,
  toggleLeftSidebar,
} from "../features/leftSidebar/leftSidebarSlice";
import LeftSidebar from "../features/leftSidebar/LeftSidebar";
import UploadComponent from "../features/messages/components/UploadComponent";

const DefaultLayout = ({ children }) => {
  const showLeftSidebar = useSelector(selectShowLeftSidebar);
  const showRightSidebar = useSelector(selectShowRightSidebar);
  const dispatch = useDispatch();

  return (
    <Container fluid className="vh-100 mt-2" style={{ backgroundColor: 'white' }}>
      <Row className="h-80">
        {showLeftSidebar && (
          <Col md={3} className={"offcanvas-col border-start"}></Col>
        )}
        <Col md={showLeftSidebar ? 9 : 12} className="chat-col">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <Button
              variant=""
              onClick={() => dispatch(toggleLeftSidebar())}
              className="me-2"
            >
              {showLeftSidebar ? (
                <i className="bi bi-x"></i>
              ) : (
                <i className="bi bi-list"></i>
              )}
            </Button>
            <h3>Loading...</h3>
            <div></div>
          </div>
          
          {children}
       
        </Col>
       
        </Row>
        <Row className="h-80">
        {showLeftSidebar && (
          <Col md={3} className={"offcanvas-col border-start"}></Col>
        )}
        <Col className="chat-col">
          <div className="d-flex flex-row justify-content-between align-items-center">
       
    
          
           
          </div>
   
       
        </Col>
       
        </Row>
    
      
      <LeftSidebar />
    </Container>
  );
};

export default DefaultLayout;
