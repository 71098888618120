import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showRightSidebar: true,
};

export const rightSidebarSlice = createSlice({
  name: "rightSidebar", // Use camelCase for slice names
  initialState,
  reducers: {
    toggleRightSidebar: (state) => {
      state.showRightSidebar = !state.showRightSidebar;
    },
    setRightSidebarVisibility: (state, action) => {
      state.showRightSidebar = action.payload; // Assuming payload is a boolean
    },
  },
});

export const { toggleRightSidebar, setRightSidebarVisibility } =
  rightSidebarSlice.actions;

export const selectShowRightSidebar = (state) =>
  state.rightSidebar.showRightSidebar; // Corrected casing for the state slice

export default rightSidebarSlice.reducer;
