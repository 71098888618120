import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Homepage from "./pages/Homepage";
import ChatLayout from "./layouts/ChatLayout";
import DefaultLayout from "./layouts/DefaultLayout";
import Chat from "./pages/Chat";
import "./App.css";
import { useEffect, useState } from "react";
import { Cookies, CookiesProvider, useCookies, withCookYies } from 'react-cookie';

function App() {
  const auth_server = `${process.env.REACT_APP_HOST_AUTH}`;
  const urlParams = new URLSearchParams(window.location.search);
  const [sessionData, setSessionData] = useState({});
  const session = urlParams.get('session');
  const [token, setToken] = useState();
  const [user, setUser] = useState();
  const [license, setLicense] = useState();
  const [cf, setCf] = useState();
  //const user = urlParams.get('user');
  //const license = urlParams.get('license');
  //const cf = urlParams.get('cf');
  useEffect(() => {
    const fetchSessionData = async () => {
      try {


        const response = await fetch(`${auth_server}/session?id=${session}`);
        if (response.ok) {
          const data = await response.json();

          if (data) {
            console.log("Realizziamo una nuova sessione");
            setSessionData(data);

          }
        } else {
          console.error("Failed to fetch session data");
        }
      } catch (error) {
        console.error("Error fetching session data:", error);
      }
    };
    if(session)
    {
    fetchSessionData();
    }
  }, []);

 

  useEffect(()=>{
    if(sessionData.token===undefined)
    {
      console.log("Sessiondata undefined richiamato senza parametri utili");
      setToken(localStorage.getItem("token"));
      setUser(localStorage.getItem("user"));
      setLicense(localStorage.getItem("license"));
      setCf(localStorage.getItem("cf"));
    }
    else
    {
   
    console.log("Nuovo Rendering DashBoard variato SessionData");
    console.log(sessionData.token);
    localStorage.setItem("token", sessionData.token);
    localStorage.setItem("user", sessionData.user); // Memorizza user come stringa JSON
    localStorage.setItem("license", sessionData.license);
    localStorage.setItem("cf", sessionData.cf);
    setToken(localStorage.getItem("token"));
    setUser(localStorage.getItem("user"));
    setLicense(localStorage.getItem("license"));
    setCf(localStorage.getItem("cf"));
    console.log("Prova di recupero da local storage"+token);
    }

  },[sessionData])



  return (
    <Router>
      <Routes>

        <Route
          path="/"
          element={
            <DefaultLayout>

              <Homepage token={token} user={user} license={license} cf={cf} />
            </DefaultLayout>
          }
        />
        <Route
          path="/chat/:chatId"
          element={
            <>
              <ChatLayout>
                <Chat />
              </ChatLayout>

            </>
          }
        />
        {/* Define other routes here */}
      </Routes>
    </Router>
  );
}

export default App;
