import React from "react";
import UserMessage from "./UserMessage";
import BotMessage from "./BotMessage";
import { selectKToken } from "../../general/keycloakTokenSlice";
import { useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode"; // NOTA: Importa jwtDecode, non jwt_decode

const areEqual = (prevProps, nextProps) => {
  const result = prevProps.message.id === nextProps.message.id;
 
  return result; // example check
};

const MessageManager = React.memo(({ message }) => {
  
  const token = useSelector(selectKToken);
  const body = message.message_body;
  const timeStamp = message.timestamp;
  const user = "test0";
  var userEmail="";
  console.log("Da messagemanager");
  console.log(message);
  let decodedToken = null;
  try {
    decodedToken = jwtDecode(token);
    userEmail = decodedToken?.email;
    //console.log(decodedToken);
  } catch (err) {
    console.error(err);
  }
  
  switch (message.message_type.toLowerCase()) {
    case "user":
      return <UserMessage messageBody={body} timeStamp={timeStamp} user={userEmail} />;
    case "bot":
      return <BotMessage  timeStamp={timeStamp} messageBody={body} />;
    case "end":
      return null;
    default:
      return <div>Unknown message type</div>;
  }
}, areEqual);

export default MessageManager;
