import React, { useState } from "react";
import moment from "moment";
import { selectUser } from "../../general/usertTypeSlice";
import { useSelector } from "react-redux";
import { Card, Button } from "react-bootstrap";
import Markdown from "marked-react";

const MessageCard = React.memo(({ messageBody, sender, position, timeStamp }) => {
  const [speaking, setSpeaking] = useState(false); 
  const user = useSelector(selectUser);
  const dataFormatoItaliano = moment(timeStamp).format("DD/MM/YYYY HH:mm:ss");

  // Funzione per avviare la sintesi vocale
  const startSpeaking = () => {
    if (messageBody && messageBody.message) {
      const utterance = new SpeechSynthesisUtterance(messageBody.message);
      speechSynthesis.speak(utterance);
      setSpeaking(true);
    }
  };


  const stopSpeaking = () => {
    speechSynthesis.cancel();
    setSpeaking(false);
  };

  return (
    <div className={`d-flex justify-content-${position}`}>
      <Card className="mb-3">
        <Card.Body style={{ width: "100%" }} className={` ${user === "privato" ? "bg-dd-privato" : "bg-dd-impresa"}`}>
          <Card.Title className={`text-${position}`}>
            {sender === "Tutor d'impresa" && <i className="bi bi-grid-fill me-2"></i>}
            {sender}{" "}
            {sender === "Utente" && <i className="bi bi-person-fill"></i>}
          </Card.Title>
          <Card.Subtitle className={`mb-2 text-muted text-${position}`}>
            {dataFormatoItaliano}
          </Card.Subtitle>
          <Markdown>{messageBody.message}</Markdown>
       
          {!speaking ? (
            <Button variant="primary" onClick={startSpeaking}>
              Avvia lettura
            </Button>
          ) : (
            <Button variant="danger" onClick={stopSpeaking}>
              Interrompi lettura
            </Button>
          )}
        </Card.Body>
      </Card>
    </div>
  );
});

export default MessageCard;
