import React, { useState, useEffect } from 'react';
import { selectKToken } from '../../../features/general/keycloakTokenSlice';
import { selectUser } from '../../general/usertTypeSlice';
import { selectLicense } from '../../general/licenseTypeSlice';
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';

const base_url_upload = `${process.env.REACT_APP_HOST_ROCCO_UPLOAD}`;


const UploadComponent = () => {
  const [file, setFile] = useState(null);
  const [fileList, setFileList] = useState([]);
  const kToken = useSelector(selectKToken);
  const User=useSelector(selectUser);
  const License=useSelector(selectLicense);

  useEffect(() => {
    // Effettua una richiesta GET per ottenere la lista dei file
    if (kToken) {
      fetch(`${base_url_upload}/get_file_list`, {
        method: 'GET',
        headers: {
     //     "user-type": "azienda",
        //  "proxy_user_license": "premium",
          Authorization: `Bearer ${kToken}`,
        },
      })
        .then(response => response.json())
        .then(data => setFileList(data.files))
        .catch(error => console.error('error', error));
    }
  }, [kToken]);
  const handleFileUpload = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('file', file);

    // Effettua una richiesta POST per l'upload del file
    fetch(`${base_url_upload}/upload_and_process_file`, {
      method: 'POST',
      headers: {
       // "user-type": "azienda",
        //"proxy_user_license": "premium",
        Authorization: `Bearer ${kToken}`,   },
      body: formData
    })
      .then(response => response.text())
      
      .catch(error => console.error('error', error));
  };
  const handleDeleteFile = (fileName) => {
    // Effettua una richiesta DELETE per eliminare il file
    fetch(`${base_url_upload}/delete_file?file_name=${fileName}`, {
      method: 'DELETE',
      headers: {
        "user-type": `${User}`,
        "proxy_user_license": `${License}`,
        Authorization: `Bearer ${kToken}`,
      },
    })
      .then(response => {
        if (response.ok) {
          // Rimuovi il file dalla lista dei file
          setFileList(prevFileList => prevFileList.filter(item => item !== fileName));
        } else {
          console.error('Errore durante l\'eliminazione del file:', response.statusText);
        }
      })
      .catch(error => console.error('error', error));
  };

  return (
    <div>
      <div>
        <h6>Documenti Caricati</h6>
        <ul>
          {fileList.map(fileName => (
            <li key={fileName}>{fileName} <br></br>
            <Button variant="danger" size="sm" onClick={() => handleDeleteFile(fileName)}>
                <i className="bi bi-trash"></i> Elimina
              </Button>
            </li>
          ))}
        </ul>
      </div>
      
     
    </div>
  );
};

export default UploadComponent;
