import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showLeftSidebar: false,
};

export const leftSidebarSlice = createSlice({
  name: "leftSidebar",
  initialState,
  reducers: {
    toggleLeftSidebar: (state) => {
      state.showLeftSidebar = !state.showLeftSidebar;
    },
    setLeftSidebarVisibility: (state, action) => {
      state.showLeftSidebar = action.payload;
    },
  },
});

export const { toggleLeftSidebar, setLeftSidebarVisibility } = leftSidebarSlice.actions;
export const selectShowLeftSidebar = (state) => state.leftSidebar.showLeftSidebar;
export default leftSidebarSlice.reducer;
