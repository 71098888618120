
// import { configureStore } from '@reduxjs/toolkit';
// import rootReducer from './rootReducer';
// import { persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
// import { persistStore } from 'redux-persist';

// const persistConfig = {
//   key: 'root',
//   storage,
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);

// export const store = configureStore({
//   reducer: persistedReducer,
// });

// export const persistor = persistStore(store);

// export default store;

//OLD SICARI
 import { configureStore } from '@reduxjs/toolkit';
 import rootReducer from './rootReducer';

 export const store = configureStore({
   reducer: rootReducer,
 });

 export default store;
