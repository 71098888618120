
import { createSlice } from '@reduxjs/toolkit';

export const cfTypeSlice = createSlice({
    name: 'cfType',
    initialState: {
      value: "free",
    },
    reducers: {
      setCfType: (state, action) => {
        state.value = action.payload;
      },
    },
  });


export const { setCfType } = cfTypeSlice.actions;
export const selectCf = (state) => state.cfType.value;
export default cfTypeSlice.reducer;