import { createSlice } from '@reduxjs/toolkit';

export const keycloakTokenSlice = createSlice({
  name: 'keycloakToken',

  initialState: {
    value: null, 
  },
  reducers: {
    setKeycloakToken: (state, action) => 
    {
      if(action.payload)
      {
   
      state.value = action.payload;
      }
    },
 
  },
});

export const { setKeycloakToken } = keycloakTokenSlice.actions;
export const selectKToken = (state) => state.keycloakToken.value;

export default keycloakTokenSlice.reducer;