import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import MessageManager from "../features/messages/components/MessageManager";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMessages,
  resetMessage,
} from "../features/messages/messagesSlice";
import { useParams } from "react-router-dom";
import {
  selectAllMessages,
  selectMessagesError,
  selectMessagesLoading,
} from "../features/messages/messagesSelectors";

import { selectKToken } from "../features/general/keycloakTokenSlice";
import { selectUser } from "../features/general/usertTypeSlice";
import { selectLicense } from "../features/general/licenseTypeSlice";
import { setActiveChat } from "../features/chats/chatsSlice";
import { selectActiveChat } from "../features/chats/chatsSelector";

const Chat = () => {
  const dispatch = useDispatch();
  const activeChat = useSelector(selectActiveChat);
  const kToken= useSelector(selectKToken);
  const user=useSelector(selectUser);
  const license=useSelector(selectLicense);

  const { chatId} = useParams();

  // Update active chat and reset messages when chat changes
  useEffect(() => {
    if (activeChat !== chatId) {
      if(chatId!=="new" )   //Sicari
      {dispatch(setActiveChat(chatId));
       dispatch(resetMessage());
      }
      }
  }, [dispatch, activeChat, chatId]);

  const messages = useSelector(selectAllMessages);
  const isLoading = useSelector(selectMessagesLoading);
  const error = useSelector(selectMessagesError);

  // Fetch messages for the chat and set up polling to fetch new messages
  useEffect(() => {
    const fetchNewMessages = () => {
       if(chatId && user && license && kToken)
       {
       
      dispatch(fetchMessages({chatId,kToken,user,license}));
       }
    };

    fetchNewMessages(); // Initial fetch
     const intervalId = setInterval(fetchNewMessages, 12000); // Poll every 10 seconds

     return () => clearInterval(intervalId); // Clean up interval on unmount
  }, [dispatch, chatId,kToken,user,license]);

  // Render loading state
  if (isLoading) {
    return <Container>Loading...</Container>;
  }

  // Render error state
  if (error) {
    return <Container>Error: {error}</Container>;
  }

  // Render chat messages
  return (
    <div>
        

        

      {messages.map((message) => (
        <>
        <div style={{width:"100%", margin: 'auto',}}>
        <MessageManager key={message.message_id} message={message} /></div>
        </>
      ))}
   
    </div>
  );
};

export default Chat;
